import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    component: () => import("../views/layout/Layout.vue"),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/Home')
      },
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicy')
      },
      {
        path: '/terms-condition',
        name: 'TermsAndCondition',
        component: () => import('../views/TermsAndCondition')
      },
      {
        path: '/refund-and-cancellation',
        name: 'RefundAndCancellation',
        component: () => import('../views/RefundAndCancellation')
      }
    ]
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFoundComponent",
    component: () => import("../views/errors/NotFoundComponent.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
